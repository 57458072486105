<template>
  <div class="flex flex-col h-full">
    <div class="flex-auto overflow-scroll">
      <div class="pt-4 px-4 mb-4">
        <div class="flex content-center">
          <div
            @click="utils.goBack(router, '/admin/recibos')"
            class="pr-2 flex items-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </div>
          <h3 class="text-2xl font-bold">Corte</h3>
        </div>
      </div>

      <!-- 			Load all providers
			Show somewhere the almacen cost
			Create recibos even thoough there is no deliveires
		-->
      <div class="flex flex-row w-full px-4 gap-4">
        <div class="w-full text-left pb-2">
          <select
            v-model="month"
            id="countries"
            class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option selected disable :value="null">Escoge el mes</option>
            <option value="1">Enero</option>
            <option value="2">Febrero</option>
            <option value="3">Marzo</option>
            <option value="4">Abril</option>
            <option value="5">Mayo</option>
            <option value="6">Junio</option>
            <option value="7">Julio</option>
            <option value="8">Agosto</option>
            <option value="9">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
          </select>
        </div>

        <div class="w-full text-left pb-2">
          <select
            v-model="year"
            id="countries"
            class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option selected disable value="">Escoge el año</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
      </div>

      <div class="w-full text-left px-4 pb-2">
        <select
          v-model="proveedor"
          v-if="proveedores.length > 0"
          id="countries"
          class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option selected :value="{ nombre: 'Todos' }">Todos</option>
          <option
            v-for="proveedor in proveedores"
            v-bind:key="proveedor.id"
            :value="proveedor"
          >
            {{ proveedor.nombre }}
          </option>
        </select>
      </div>

      <div
        v-if="proveedor.id"
        class="border boder-gray-300 rounded bg-white m-4"
      >
        <div class="overflow-scroll">
          <table class="w-full text-sm text-left">
            <thead class="text-s border-b">
              <th scope="col" class="py-3 px-6">Periodo</th>
              <th v-if="proveedor.ecom" scope="col" class="py-3 px-6">
                Tienda en línea
              </th>
              <th scope="col" class="py-3 px-6">Almacén</th>
              <th scope="col" class="py-3 px-6">Envíos</th>
              <th scope="col" class="py-3 px-6">Extras</th>
              <th scope="col" class="py-3 px-6">Total (c/IVA)</th>
            </thead>
            <tbody>
              <tr class="text-s">
                <td class="py-3 px-6">{{ month }}/{{ year }}</td>
                <td v-if="proveedor.ecom" class="py-3 px-6">
                  ${{ proveedor.pricing.ecom_base }}
                  <!-- + {{proveedor.pricing.ecom_comision}}%		 -->
                </td>
                <td class="py-3 px-6">
                  ${{
                    calculateAlmacen()
                      ? Math.round(calculateAlmacen() * 100) / 100
                      : 0
                  }}
                </td>
                <td class="py-3 px-6">
                  ${{
                    filteredEnvios.length > 0
                      ? Math.round(
                          filteredEnvios
                            .map((e) => {
                              return e.costo;
                            })
                            .reduce((a, b) => a + b) * 100
                        ) / 100
                      : 0
                  }}
                </td>
                <td class="py-3 px-6">
                  ${{
                    filteredEnvios.length > 0
                      ? Math.round(
                          filteredEnvios
                            .map((e) => {
                              return e.cargo_extra || 0;
                            })
                            .reduce((a, b) => a + b) * 100
                        ) / 100
                      : 0
                  }}
                </td>
                <td class="py-3 px-6">
                  ${{
                    filteredEnvios.length > 0
                      ? Math.round(
                          (filteredEnvios
                            .map((e) => {
                              return e.costo;
                            })
                            .reduce((a, b) => a + b) +
                            calculateAlmacen() +
                            filteredEnvios
                              .map((e) => {
                                return e.cargo_extra || 0;
                              })
                              .reduce((a, b) => a + b)) *
                            100 *
                            1.16
                        ) / 100
                      : 0
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <label class="block text-gray-700 text-sm font-bold pl-4 mt-4">
        Envíos
      </label>

      <empty
        v-if="filteredEnvios && filteredEnvios.length == 0"
        :texto="'No hubo entregas'"
      >
      </empty>

      <div
        v-if="filteredEnvios && filteredEnvios.length > 0"
        class="w-full text-left p-4"
      >
        <div class="border boder-gray-300 rounded bg-white overflow-hidden">
          <div class="overflow-scroll">
            <table class="w-full text-sm text-left">
              <thead class="text-s border-b border-b-gray-200">
                <th scope="col" class="py-3 px-6">Pedido</th>
                <th scope="col" class="py-3 px-6">Status</th>
                <th scope="col" class="py-3 px-6">Fecha</th>
                <th scope="col" class="py-3 px-6">Distancia</th>
                <th scope="col" class="py-3 px-6">Costo</th>
                <th scope="col" class="py-3 px-6">Extra</th>
                <tr></tr>
              </thead>
              <tbody v-for="envio in filteredEnvios" v-bind:key="envio.id">
                <tr class="text-s border-b">
                  <td class="py-3 px-6 underline">
                    <router-link
                      :to="`/proveedor/${envio.pedido.proveedor.id}/orden/${envio.pedido.id}`"
                    >
                      {{ envio.pedido.id }}
                    </router-link>
                  </td>
                  <td class="py-3 px-6">
                    {{ envio.status }}
                  </td>
                  <td class="py-3 px-6">
                    {{
                      envio.created_at
                        ? new Date(envio.created_at).toLocaleDateString(
                            "en-GB",
                            { timeZone: "America/Mexico_City" }
                          )
                        : ""
                    }}
                  </td>
                  <td class="py-3 px-6">
                    {{ Math.ceil(envio.distancia / 10) / 100 }} km
                  </td>
                  <td class="py-3 px-6">
                    ${{ Math.round(envio.costo * 100) / 100 }}
                  </td>
                  <td class="py-3 px-6">
                    ${{ Math.round((envio.cargo_extra || 0) * 100) / 100 }}
                  </td>
                  <td>
                    <div
                      @click="
                        showEnvio[envio.id] == null
                          ? (showEnvio[envio.id] = true)
                          : (showEnvio[envio.id] = !showEnvio[envio.id])
                      "
                      class="cursor-pointer flex-none"
                    >
                      <div v-if="!showEnvio[envio.id]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      </div>
                      <div class="h-6 w-6" v-if="showEnvio[envio.id]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="text-s border-b" v-if="showEnvio[envio.id]">
                  <td colspan="7" class="py-2 px-4">
                    <div class="flex flex-row space-x-4">
                      <div class="w-full">
                        <p>Costo</p>
                        <input
                          class="mt-2 w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="nombre"
                          type="number"
                          placeholder="0"
                          min="0"
                          v-model="envio.new_costo"
                        />
                      </div>

                      <div class="w-full">
                        <p>Cargo extra</p>
                        <input
                          class="mt-2 w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="nombre"
                          type="number"
                          placeholder="0"
                          min="0"
                          v-model="envio.new_extra"
                        />
                      </div>
                    </div>
                    <button
                      class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4 mt-2"
                      @click="updateEnvio(envio)"
                    >
                      Actualizar
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot class="">
                <tr>
                  <th scope="col" class="py-3 px-6">Total</th>
                  <th scope="col" class="py-3 px-6">
                    {{ filteredEnvios.length }}
                  </th>
                  <th scope="col" class="py-3 px-6"></th>
                  <th scope="col" class="py-3 px-6"></th>
                  <th scope="col" class="py-3 px-6">
                    ${{
                      Math.round(
                        filteredEnvios
                          .map((e) => {
                            return e.costo;
                          })
                          .reduce((a, b) => a + b) * 100
                      ) / 100
                    }}
                  </th>
                  <th scope="col" class="py-3 px-6">
                    ${{
                      Math.round(
                        filteredEnvios
                          .map((e) => {
                            return e.cargo_extra || 0;
                          })
                          .reduce((a, b) => a + b) * 100
                      ) / 100
                    }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="proveedor.id && year && month"
      class="p-4 flex-none border-t border-t-gray-300"
    >
      <button
        class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4"
        @click="hacerCorte()"
      >
        Hacer corte
      </button>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { supabase } from "../supabase";
import { onMounted, ref, computed } from "vue";
import { useLoading } from "vue-loading-overlay";
import { utils } from "./utils/utils.js";
import { useToast } from "vue-toast-notification";

const loading = useLoading({
  color: "#428b74",
  loader: "spinner",
});

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const envios = ref([]);
    const proveedor = ref({ nombre: "Todos" });
    const proveedores = ref([]);
    const month = ref("1");
    const year = ref("2025");
    const showEnvio = ref([]);

    async function getEnvios() {
      let { data, error } = await supabase
        .from("envios")
        .select("*, pedido!inner(id, cancelado, proveedor(id, nombre, ecom))")
        .order("created_at", { ascending: true })
        .eq("pedido.cancelado", false)
        .neq("status", "cancelado")
        .gt("created_at", "2023-09-01T00:00:00.000000+00:00")
        .is("recibo", null);

      console.log(data, error);

      if (data) {
        return data;
      } else if (error) {
        console.log("getEnvios", error);
        throw error;
      }
    }

    const filteredEnvios = computed(() => {
      if (!month.value || !year.value) {
        return [];
      }

      if (!proveedor.value.id) {
        return envios.value.filter((e) => e.created_at < getCorte());
      } else {
        return envios.value
          .filter((e) => e.pedido.proveedor.id == proveedor.value.id)
          .filter((e) => e.created_at < getCorte());
      }
    });

    function getCorte() {
      return `${month.value == "12" ? Number(year.value) + 1 : year.value}-${
        month.value == "12"
          ? "01"
          : String(Number(month.value) + 1).padStart(2, "0")
      }-01T00:00:00.000000+00:00`;
    }
    onMounted(async () => {
      proveedores.value = await utils.getProveedores();
      envios.value = await getEnvios();
    });

    async function hacerCorte() {
      if (
        filteredEnvios.value.filter((e) => e.status == "pendiente").length > 0
      ) {
        return alert("Hay envíios pendientes");
      }

      if (!month.value || !year.value) {
        return alert("Favor de escoger una fecha de corte.");
      }

      if (confirm("¿Quieres hacer este corte?")) {
        let loader = loading.show();

        let existing_recibos = await utils.getReciboPorMes(
          month.value,
          year.value,
          proveedor.value.id
        );

        if (existing_recibos.length > 0) {
          loader.hide();

          return alert(
            "Un recibo ya existe por este proveedor por este periodo."
          );
        }

        let totalEnvio =
          filteredEnvios.value.length > 0
            ? filteredEnvios.value
                .map((e) => {
                  return e.costo;
                })
                .reduce((a, b) => a + b)
            : 0;
        let totalExtras =
          filteredEnvios.value.length > 0
            ? filteredEnvios.value
                .map((e) => {
                  return e.cargo_extra || 0;
                })
                .reduce((a, b) => a + b)
            : 0;

        let recibo = {
          proveedor: proveedor.value.id,
          mes: Number(month.value),
          año: year.value,
          almacen: calculateAlmacen(),
          envios: totalEnvio,
          extras: totalExtras,
        };

        if (proveedor.value.ecom && proveedor.value.pricing.ecom_base) {
          recibo.ecom = proveedor.value.pricing.ecom_base;
        }

        console.log("Crear:", recibo);
        console.log(filteredEnvios.value);

        let data = await createRecibo(recibo);

        let recibo_id = data.id;

        let envios_to_update = JSON.parse(JSON.stringify(filteredEnvios.value));

        console.log("envios_to_update", envios_to_update);

        if (envios_to_update.length > 0) {
          await updateEnvios(
            envios_to_update.map((e) => {
              delete e.new_costo;
              delete e.new_extra;

              e.recibo = recibo_id;
              e.pedido = e.pedido.id;
              return e;
            })
          );
        }

        envios.value = await getEnvios();
        proveedor.value = { nombre: "Todos" };

        loader.hide();
      }
    }

    function calculateAlmacen() {
      if (!proveedor.value.tarimas) {
        return 0;
      }

      return proveedor.value.tarimas * proveedor.value.pricing.almacen;
    }

    async function updateEnvio(envio_to_update) {
      let new_envio = { ...envio_to_update };

      if (envio_to_update.new_costo) {
        new_envio.costo = envio_to_update.new_costo;
      }

      if (envio_to_update.new_extra) {
        new_envio.cargo_extra = envio_to_update.new_extra;
      }

      delete new_envio.new_costo;
      delete new_envio.new_extra;

      new_envio.pedido = envio_to_update.pedido.id;

      console.log(new_envio);

      let error = await updateEnvios([new_envio]);

      if (error) {
        useToast().error("Envio no actualizado", { position: "top-right" });
        return;
      }

      if (envio_to_update.new_costo) {
        envio_to_update.costo = new_envio.costo;
      }

      if (envio_to_update.new_extra) {
        envio_to_update.cargo_extra = new_envio.cargo_extra;
      }

      delete envio_to_update.new_costo;
      delete envio_to_update.new_extra;

      showEnvio.value[envio_to_update.id] = false;

      useToast().success("Envis actualizado", { position: "top-right" });
    }

    async function updateEnvios(envios_to_update) {
      console.log("envios", envios_to_update);
      let { data, error } = await supabase
        .from("envios")
        .upsert(envios_to_update);

      console.log(data, error);

      return error;
    }

    async function createRecibo(recibo) {
      let { data, error } = await supabase
        .from("recibos")
        .upsert([recibo])
        .single();

      console.log(data, error);
      return data;
    }

    return {
      route,
      router,
      envios,
      proveedores,
      filteredEnvios,
      proveedor,
      hacerCorte,
      month,
      year,
      calculateAlmacen,
      utils,
      showEnvio,
      updateEnvio,
    };
  },
};
</script>
