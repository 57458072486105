import { supabase } from "../../supabase";

function goBack(router, backup) {
  if (window.history.state.back === null) {
    return router.push(backup);
  }

  window.history.back();
}

export const utils = {
  goBack: goBack,
  isPendiente: isPendiente,
  requireEntregaSurtir: requireEntregaSurtir,
  getCliente: getCliente,
  getClienteProductoPrecios: getClienteProductoPrecios,
  getClienteProductos: getClienteProductos,
  getClienteProveedores: getClienteProveedores,
  getPedidosPorCliente: getPedidosPorCliente,
  getPedido: getPedido,
  getProducto: getProducto,
  updateProducto: updateProducto,
  getProductoPrecio: getProductoPrecio,
  updateProductoPrecio: updateProductoPrecio,
  getProductoPrecios: getProductoPrecios,
  getProveedor: getProveedor,
  getPedidosPorProveedor: getPedidosPorProveedor,
  updatePedido: updatePedido,
  updateProductoProp: updateProductoProp,
  getProductosPorProveedor: getProductosPorProveedor,
  getClienteProductosPorProveedor: getClienteProductosPorProveedor,
  createClienteProducto: createClienteProducto,
  deleteClienteProducto: deleteClienteProducto,
  getProductosTodoChido: getProductosTodoChido,
  getAllProductosNotTodoChido: getAllProductosNotTodoChido,
  deleteProductoPrecio: deleteProductoPrecio,
  uploadFile: uploadFile,
  getURL: getURL,
  deletePedidoFactura: deletePedidoFactura,
  crearPedidoFactura: crearPedidoFactura,
  getProveedores: getProveedores,
  crearProveedor: crearProveedor,
  updateLineItems: updateLineItems,
  updateInventory: updateInventory,
  getRecibos: getRecibos,
  getRecibo: getRecibo,
  getAllPayouts: getAllPayouts,
  getPayouts: getPayouts,
  getPayout: getPayout,
  updateRecibo: updateRecibo,
  getAllRecibos: getAllRecibos,
  crearEnvio: crearEnvio,
  updateEnvio: updateEnvio,
  upperCase: upperCase,
  getClientes: getClientes,
  upsertDireccion: upsertDireccion,
  getDireccion: getDireccion,
  getDirecciones: getDirecciones,
  getDataEcom: getDataEcom,
  updateProveedor: updateProveedor,
  getCatalogo: getCatalogo,
  getReciboPorMes: getReciboPorMes,
  getProveedorPricing: getProveedorPricing,
  getPricings: getPricings,
  updateCliente: updateCliente,
  getProductsById: getProductsById,
  getUniqueID: getUniqueID,
  deleteFile: deleteFile,
  getEntregas: getEntregas,
  getURLWrapper: getURLWrapper,
  uploadFileHandler: uploadFileHandler,
};

async function deleteFile(bucket, file) {
  if (!bucket || !file) {
    console.error("Bucket or file is missing:", { bucket, file });
    return;
  }

  if (typeof file !== "string") {
    console.error("File must be a string:", file);
    return;
  }

  try {
    let files = [file];
    const { data, error } = await supabase.storage
      .from("comprobantes")
      .remove(files);

    if (error) {
      console.error("Error deleting file:", error.message);
    } else {
      console.log("DELETE SUCCESS:", data);
    }
  } catch (err) {
    console.error("Unexpected error:", err);
  }
}

async function checkID(type, id) {
  let { data, error } = await supabase.from(type).select("id").eq("id", id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getCliente", error);
    throw error;
  }
}

async function getCliente(cliente_id) {
  let { data, error } = await supabase
    .from("clientes")
    .select("*")
    .eq("id", cliente_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getCliente", error);
    throw error;
  }
}

async function getClientes(proveedor_id) {
  let { data, error } = await supabase
    .from("cliente_proveedor")
    .select("proveedor, cliente(*)")
    .match({
      proveedor: proveedor_id,
    });

  if (data) {
    return data
      .sort((a, b) => {
        let fa = a.cliente.nombre.toLowerCase(),
          fb = b.cliente.nombre.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
      .map((d) => {
        return d.cliente;
      });
  } else if (error) {
    console.log("utils getClientes", error);
    throw error;
  }
}

async function getClienteProductoPrecios(cliente_id) {
  let { data, error } = await supabase
    .from("cliente_producto_precio")
    .select("producto_precio(id, producto, precio)")
    .eq("cliente", cliente_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getClienteProductoPrecios", error);
    throw error;
  }
}

async function getClienteProductos(cliente_id) {
  let { data, error } = await supabase
    .from("cliente_producto")
    .select("id, producto(id, nombre, proveedor)")
    .eq("cliente", cliente_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getClienteProductos", error);
    throw error;
  }
}

async function getClienteProveedores(cliente_id) {
  let { data, error } = await supabase
    .from("cliente_proveedor")
    .select(
      "activo, cliente, require_aprobacion, proveedor(id, standalone, mensaje, minimo, nombre, productos(archivado, proveedor, id, nombre, precio, activo, cantidad, foto))"
    )
    .eq("activo", true)
    .eq("cliente", cliente_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getClienteProveedores", error);
    throw error;
  }
}

async function getPedidosPorCliente(cliente_id) {
  let { data, error } = await supabase
    .from("pedidos")
    .select(
      "id, envios(*), standalone, cancelado, entregado, pagado, envio, proveedor (id, nombre, payments, standalone), require_factura, evidencia, require_entrega, require_aprobacion, aprobado, cliente(id), created_at, surtido, pedido_factura(*), line_items!inner(precio, cantidad, cantidad_entregada, producto (id, nombre, proveedor (nombre, standalone)))"
    )
    .order("created_at", { ascending: false })
    .eq("cliente", cliente_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getPedidosPorCliente", error);
    throw error;
  }
}

async function getEntregas(proveedor_id) {
  let { data, error } = await supabase
    .from("pedidos")
    .select(
      "id, standalone, datos_entrega, cancelado, entregado, require_factura, evidencia, cliente(id, nombre), created_at, pedido_factura(*), line_items!inner(precio, cantidad, cantidad_entregada, producto (id, nombre, proveedor (nombre)))"
    )
    .order("created_at", { ascending: false })
    .eq("proveedor", proveedor_id)
    .or(
      "and(entregado.eq.true,evidencia.is.null),entregado.eq.false,and(standalone.eq.true,entregado.is.null)"
    );
  // .or('entregado.eq.false')

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getEntregas", error);
    throw error;
  }
}

async function getPedido(pedido_id) {
  let { data, error } = await supabase
    .from("pedidos")
    .select(
      "id, standalone, envios(*), comprobantes, ecom, datos_entrega, cancelado, instrucciones, costo, envio, entregado, pagado, proveedor (id, nombre, shop_name, standalone, payments), require_aprobacion, require_entrega, require_factura, aprobado, cliente (*), created_at, surtido, evidencia, pedido_factura(id, url, proveedor ( nombre )), line_items!inner(id, pedido, cantidad, cantidad_entregada, precio, producto (id, nombre, precio, cantidad, proveedor (id, nombre)))"
    )
    .eq("id", pedido_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getPedido", error);
    throw error;
  }
}

async function updateCliente(cliente_id, data) {
  let { error } = await supabase
    .from("clientes")
    .update(data)
    .eq("id", cliente_id);

  if (error) {
    console.log("utils updateCliente", error);
    throw error;
  } else {
    return;
  }
}

async function updateRecibo(recibo_id, data) {
  let { error } = await supabase
    .from("recibos")
    .update(data)
    .eq("id", recibo_id);

  if (error) {
    console.log("utils updateRecibo", error);
    throw error;
  } else {
    return;
  }
}

async function updatePedido(pedido_id, data) {
  let { error } = await supabase
    .from("pedidos")
    .update(data)
    .eq("id", pedido_id);

  if (error) {
    console.log("utils updatePedido", error);
    throw error;
  } else {
    return;
  }
}

async function getProductosPorProveedor(proveedor_id) {
  let { data, error } = await supabase
    .from("productos")
    .select(
      "id, nombre, precio, activo, cantidad, foto, archivado, proveedor, producto_precio(*)"
    )
    .eq("proveedor", proveedor_id)
    .order("nombre", { ascending: false });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProductosPorProveedor", error);
    throw error;
  }
}

async function getDataEcom(proveedor_shop_name) {
  let { data, error } = await supabase
    .from("proveedores")
    .select(
      "*, pricing(*), productos(id, nombre, precio, precio_publico, texto, activo, archivado, cantidad, foto)"
    )
    .eq("shop_name", proveedor_shop_name)
    .eq("productos.archivado", false)
    .eq("productos.ecom", true)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProductosPorProveedor", error);
    throw error;
  }
}

async function getPricings() {
  let { data, error } = await supabase.from("pricings").select("*");

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getPricings", error);
    throw error;
  }
}

async function getCatalogo(proveedor_id) {
  if (proveedor_id == 21) {
    let { data, error } = await supabase
      .from("productos")
      .select(
        "id, nombre, precio, precio_publico, texto, activo, archivado, cantidad, foto, precioTodoChido, todoChido"
      )
      .eq("todoChido", true)
      .eq("activo", true)
      .eq("archivado", false);

    if (data) {
      return {
        nombre: "TodoChido",
        productos: data,
      };
    } else if (error) {
      console.log("utils getProductosPorProveedor", error);
      throw error;
    }
  } else {
    let { data, error } = await supabase
      .from("proveedores")
      .select(
        "id, free_delivery_cutoff, shop_name, logo, color, nombre, productos(id, nombre, precio, precio_publico, texto, activo, archivado, cantidad, foto)"
      )
      .eq("id", proveedor_id)
      .eq("productos.activo", true)
      .eq("productos.archivado", false)
      .single();

    if (data) {
      return data;
    } else if (error) {
      console.log("utils getProductosPorProveedor", error);
      throw error;
    }
  }
}

async function updateProductoProp(producto_id, data) {
  let { error } = await supabase
    .from("productos")
    .update(data)
    .eq("id", producto_id);

  if (error) {
    console.log("utils updateProductoProp", error);
    throw error;
  } else {
    return;
  }
}

async function getProductosTodoChido() {
  let { data, error } = await supabase
    .from("productos")
    .select(
      "id, nombre, precio, precioTodoChido, activo, cantidad, foto, proveedor, archivado"
    )
    .eq("todoChido", true);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProductosTodoChido", error);
    throw error;
  }
}

async function getAllProductosNotTodoChido() {
  let { data, error } = await supabase
    .from("productos")
    .select(
      "id, nombre, precio, precioTodoChido, activo, cantidad, foto, proveedor(nombre), archivado"
    )
    .eq("todoChido", false)
    .eq("archivado", false);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getAllProductosNotTodoChido", error);
    throw error;
  }
}

async function getClienteProductosPorProveedor(proveedor_id, cliente_id) {
  let { data, error } = await supabase
    .from("cliente_producto")
    .select("id, producto!inner(id, nombre, proveedor)")
    .eq("producto.proveedor", proveedor_id)
    .eq("cliente", cliente_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getClienteProductosPorProveedor", error);
    throw error;
  }
}

async function deleteClienteProducto(cliente_producto) {
  const { error } = await supabase
    .from("cliente_producto")
    .delete()
    .match({ id: cliente_producto.id });

  if (error) {
    console.log("utils deleteClienteProducto", error);
    throw error;
  } else {
    return;
  }
}

async function deletePedidoFactura(pedidoFactura) {
  const { error } = await supabase
    .from("pedido_factura")
    .delete()
    .match({ id: pedidoFactura.id });

  if (error) {
    console.log("utils deletePedidoFactura", error);
    throw error;
  } else {
    return;
  }
}

async function createClienteProducto(cliente_id, producto_id) {
  let { data, error } = await supabase
    .from("cliente_producto")
    .insert([
      {
        cliente: cliente_id,
        producto: producto_id,
      },
    ])
    .select("id, producto(id, nombre)")
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils createClienteProducto", error);
    throw error;
  }
}

async function getPedidosPorProveedor(proveedor_id) {
  let { data, error } = await supabase
    .from("pedidos")
    .select(
      "id, standalone, envios(*), ecom, proveedor(id, nombre, standalone, payments), instrucciones, cancelado, envio, require_entrega, entregado, pagado, require_factura, require_aprobacion, aprobado, cliente (id, nombre, rfc, rfc_enlace), remision_firmada, created_at, surtido, pedido_factura(*), line_items!inner(cantidad, cantidad_entregada, precio, producto!inner(id, nombre, precio, proveedor(id, nombre)))"
    )
    .order("created_at", { ascending: false })
    .eq("proveedor", proveedor_id);

  if (data) {
    console.log(data);
    return data;
  } else if (error) {
    console.log("utils getPedidosPorProveedor", error);
    throw error;
  }
}

async function getProveedorPricing(proveedor_id) {
  let { data, error } = await supabase
    .from("proveedores")
    .select("pricing(*)")
    .eq("id", proveedor_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProveedorPricing", error);
    throw error;
  }
}

async function getProveedor(proveedor_id) {
  let { data, error } = await supabase
    .from("proveedores")
    .select("*, pricing(*)")
    .eq("id", proveedor_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProveedor", error);
    throw error;
  }
}

async function getProducto(producto_id) {
  let { data, error } = await supabase
    .from("productos")
    .select("*")
    .eq("id", producto_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProducto", error);
    throw error;
  }
}

async function getProductoPrecios(producto_id) {
  let { data, error } = await supabase
    .from("producto_precio")
    .select("id, nombre, producto!inner(id, proveedor), precio)")
    .eq("producto", producto_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProductoPrecios", error);
    throw error;
  }
}

async function getProductoPrecio(producto_precio_id) {
  let { data, error } = await supabase
    .from("producto_precio")
    .select("id, nombre, producto, precio")
    .eq("id", producto_precio_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProductoPrecios", error);
    throw error;
  }
}

async function updateProducto(producto) {
  const { data, error } = await supabase
    .from("productos")
    .upsert(producto)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils updateProducto", error);
    throw error;
  }
}

async function updateProductoPrecio(prouctoPrecio) {
  const { data, error } = await supabase
    .from("producto_precio")
    .upsert(prouctoPrecio)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils updateProductoPrecio", error);
    throw error;
  }
}

async function deleteProductoPrecio(productoPrecio_id) {
  const { data, error } = await supabase
    .from("producto_precio")
    .delete()
    .match({ id: productoPrecio_id });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils deleteProductoPrecio", error);
    throw error;
  }
}

async function uploadFileHandler(type, evt) {
  if (!evt.target.files || evt.target.files.length === 0) {
    throw new Error("Selecciona un archivo");
  }
  const file = evt.target.files[0];
  const fileExt = file.name.split(".").pop();
  const fileName = `${Math.random()}.${fileExt}`;
  const filePath = `${fileName}`;

  return await uploadFile(type, filePath, file);
}

async function getURLWrapper(type, file) {
  let fileOnly = file.split(`${type}/`)[1];
  return await getURL(type, fileOnly);
}

async function uploadFile(type, filePath, file) {
  let { data, error } = await supabase.storage
    .from(type)
    .upload(filePath, file);

  if (data && data.Key) {
    return data.Key;
  } else if (error) {
    console.log("utils uploadFile", error);
    throw error;
  }
}

async function getURL(type, file) {
  const { data, error } = supabase.storage.from(type).getPublicUrl(file);

  if (data && data.publicURL) {
    return data.publicURL;
  } else if (error) {
    console.log("utils getURL", error);
    throw error;
  }
}

async function crearPedidoFactura(pedido_id, proveedor_id, url) {
  let { data, error } = await supabase
    .from("pedido_factura")
    .insert([
      {
        proveedor: proveedor_id,
        pedido: pedido_id,
        url: url,
      },
    ])
    .select("id, url, proveedor(nombre)")
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils crearPedidoFactura", error);
    throw error;
  }
}

async function getProveedores() {
  let { data, error } = await supabase
    .from("proveedores")
    .select("id, nombre, tarimas, ecom, pricing(*)")
    .order("nombre", { ascending: true });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getProveedores", error);
    throw error;
  }
}

async function getRecibos(proveedor_id) {
  let { data, error } = await supabase
    .from("recibos")
    .select("*")
    .eq("proveedor", proveedor_id)
    .order("created_at", { ascending: true });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getRecibos", error);
    throw error;
  }
}

async function getPayouts(proveedor_id) {
  let { data, error } = await supabase
    .from("payouts")
    .select("*")
    .eq("proveedor", proveedor_id)
    .order("created_at", { ascending: true });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getPayouts", error);
    throw error;
  }
}

async function getReciboPorMes(mes, año, proveedor_id) {
  let { data, error } = await supabase
    .from("recibos")
    .select("*")
    .eq("proveedor", proveedor_id)
    .eq("mes", mes)
    .eq("año", año);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getReciboPorMes", error);
    throw error;
  }
}

async function getAllRecibos() {
  let { data, error } = await supabase
    .from("recibos")
    .select("*, proveedor(id, nombre)")
    .order("created_at", { ascending: true })
    .order("proveedor", { ascending: true });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getAllRecibos", error);
    throw error;
  }
}

async function getAllPayouts() {
  let { data, error } = await supabase
    .from("payouts")
    .select("*, proveedor(id, nombre)")
    .order("created_at", { ascending: true })
    .order("proveedor", { ascending: true });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getAllPayouts", error);
    throw error;
  }
}

async function getPayout(payout_id) {
  let { data, error } = await supabase
    .from("payouts")
    .select("*")
    .eq("id", payout_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getPayout", error);
    throw error;
  }
}

async function getRecibo(recibo_id) {
  let { data, error } = await supabase
    .from("recibos")
    .select("*")
    .eq("id", recibo_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getRecibo", error);
    throw error;
  }
}

async function getUniqueID(type) {
  let id;

  while (!id) {
    let idToCheck = generateRandomID();

    let existingRecord = await checkID(type, idToCheck);

    if (existingRecord.length > 0) {
      console.log("record exists", existingRecord[0]);
    } else {
      console.log("record does not exists", idToCheck);
      id = idToCheck;
      break;
    }
  }

  return id;
}

function generateRandomID() {
  let min = Math.ceil(10000);
  let max = Math.floor(99999);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

async function crearProveedor(nombre) {
  let id = await getUniqueID("proveedores");

  let { data, error } = await supabase.from("proveedores").insert([
    {
      id: id,
      nombre: nombre,
    },
  ]);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils crearProveedor", error);
    throw error;
  }
}

async function upsertDireccion(direccion) {
  let { data, error } = await supabase
    .from("direcciones")
    .upsert([direccion])
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils upsertDireccion", error);
    throw error;
  }
}

async function getDireccion(direccion_id) {
  let { data, error } = await supabase
    .from("direcciones")
    .select("*")
    .eq("id", direccion_id)
    .single();

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getDireccion", error);
    throw error;
  }
}

async function getDirecciones(proveedor_id) {
  let { data, error } = await supabase
    .from("direcciones")
    .select("*")
    .eq("proveedor", proveedor_id);

  if (data) {
    return data;
  } else if (error) {
    console.log("utils getDirecciones", error);
    throw error;
  }
}

async function updateLineItems(pedido) {
  let line_items = pedido.line_items.map((li) => {
    return {
      id: li.id,
      cantidad_entregada: li.cantidad_entregada,
      cantidad: li.cantidad,
      producto: li.producto.id,
      pedido: li.pedido,
    };
  });

  const { error } = await supabase.from("line_items").upsert(line_items);

  if (error) {
    console.log("utils crearProveedor", error);
    throw error;
  } else {
    return;
  }
}

async function updateInventory(line_items) {
  const { data, error } = await supabase.rpc("update_multiple_inventory", {
    payload: line_items.map((l) => {
      return {
        id: l.producto.id,
        cantidad: l.cantidad,
      };
    }),
  });

  if (data) {
    return data;
  } else if (error) {
    console.log("utils updateInventory", error);
    throw error;
  }
}

function isPendiente(type, pedido) {
  switch (type) {
    case "admin":
      if (
        (pedido.cliente.id == 16 || pedido.cliente.id == 112) &&
        !pedido.surtido
      ) {
        return true;
      } else if (pedido.require_entrega && !pedido.surtido) {
        return true;
      } else if (
        pedido.require_entrega &&
        pedido.envios.filter((e) => e.status == "exitoso").length == 0
      ) {
        return true;
      } else if (
        pedido.require_entrega &&
        pedido.envios.filter(
          (e) =>
            (e.status == "exitoso" || e.status == "fallido") &&
            (!e.evidencia || e.evidencia.length == 0)
        ).length > 0
      ) {
        return true;
      }

      return false;
    case "proveedor":
      if (pedido.standalone) {
        if (
          pedido.require_factura &&
          (!pedido.pedido_factura || pedido.pedido_factura.length == 0)
        ) {
          return true;
        } else if (pedido.require_entrega && !pedido.entregado) {
          return true;
        }
      } else {
        if (pedido.require_aprobacion && !pedido.aprobado) {
          return true;
        } else if (
          pedido.require_factura &&
          (!pedido.pedido_factura || pedido.pedido_factura.length == 0)
        ) {
          return true;
        } else if (pedido.require_entrega && !pedido.surtido) {
          return true;
        } else if (
          pedido.require_factura &&
          !pedido.pagado &&
          pedido.proveedor.payments
        ) {
          return true;
        } else if (
          pedido.require_entrega &&
          pedido.envios.filter((e) => e.status == "exitoso").length == 0
        ) {
          return true;
        } else if (
          (pedido.cliente.id == 16 || pedido.cliente.id == 112) &&
          !pedido.surtido
        ) {
          return true;
        }
      }
      return false;
    case "cliente":
      if (pedido.standalone) {
        if (pedido.require_entrega && !pedido.entregado) {
          return true;
        }
      } else {
        if (pedido.require_aprobacion && !pedido.aprobado) {
          return true;
        } else if (pedido.require_entrega && !pedido.surtido) {
          return true;
        } else if (
          pedido.require_factura &&
          !pedido.pagado &&
          pedido.proveedor.payments
        ) {
          return true;
        } else if (
          pedido.require_entrega &&
          pedido.envios.filter((e) => e.status == "exitoso").length == 0
        ) {
          return true;
        } else if (
          (pedido.cliente.id == 16 || pedido.cliente.id == 112) &&
          !pedido.surtido
        ) {
          return true;
        }
      }

      return false;
  }
}

function requireEntregaSurtir(pedido) {
  //If pedido is Vinos Chidos or TBC, no requiere entrega pero si requeire surtir
  //If pedido is is not VC or TBC and does not require entrega, then it does not require surtir
  //Also need to update Pendiente

  if (pedido.cancelado) {
    return {
      entrega: false,
      surtir: false,
    };
  } else if (
    (pedido.cliente.id == 16 || pedido.cliente.id == 112) &&
    !pedido.surtido
  ) {
    return {
      entrega: false,
      surtir: true,
    };
  } else if (pedido.require_entrega) {
    return {
      entrega: true,
      surtir: true,
    };
  } else {
    return {
      entrega: false,
      surtir: false,
    };
  }
}

async function crearEnvio(pedido) {
  let direccion = {
    calle: pedido.datos_entrega.direccion.calle,
    interior: pedido.datos_entrega.direccion.interior,
    exterior: pedido.datos_entrega.direccion.exterior,
    codigo: pedido.datos_entrega.direccion.codigo,
    colonia: pedido.datos_entrega.direccion.colonia,
    estado: pedido.datos_entrega.direccion.estado,
  };

  let horario = pedido.datos_entrega.horario;

  let contacto = {
    nombre: pedido.datos_entrega.contacto.nombre,
    telefono: pedido.datos_entrega.contacto.telefono,
  };

  let envio = {
    pedido: pedido.id,
    recipiente: pedido.datos_entrega.recipiente,
    direccion: direccion,
    horario: horario,
    contacto: contacto,
    evidencia: pedido.evidencia,
    instrucciones: pedido.instrucciones,
    distancia: pedido.distancia,
    costo: pedido.costo,
    type: pedido.envio_type,
    status: "pendiente",
  };

  return await insertEnvio(envio);
}

async function insertEnvio(envio) {
  let { data, error } = await supabase.from("envios").insert(envio).single();

  if (error) {
    console.log(`Error creating envio para pedido: ${envio.pedido}`, error);
  } else {
    console.log(`Envio creado para pedido: ${envio.pedido}`);
  }

  return data;
}

function upperCase(str) {
  const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
}

async function updateEnvio(envio_id, data) {
  let { error } = await supabase.from("envios").update(data).eq("id", envio_id);

  if (error) {
    console.log("utils updateEnvio", error);
    throw error;
  } else {
    return;
  }
}

async function updateProveedor(proveedor_id, data) {
  let { error } = await supabase
    .from("proveedores")
    .update(data)
    .eq("id", proveedor_id);

  if (error) {
    console.log("utils updateProveedor", error);
    throw error;
  } else {
    return;
  }
}

async function getProductsById(ids) {
  const { data, error } = await supabase
    .from("productos")
    .select("id, cantidad")
    .in("id", ids);

  if (error) {
    console.log("utils getProductsById", error);
    throw error;
  } else {
    return data;
  }
}
